<template>
    <div class="content_body" v-loading="loading">
        <div class="nav_header border_bottom pad_15 border_bottom">
            {{title}}
        </div>

        <div class="pad_15">
            内容
        </div>

         <div class="footer pad_15 text_center border_top">
             <el-button size="small" @click="cancelClick">取 消</el-button>
             <el-button size="small" type="primary">确 定</el-button>
         </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                loading:false,
                title:"测试"
            }
        },
        methods:{
            cancelClick:function () {
                 var that = this;
                 that.$router.go(-1);
            }
        }

    }
</script>

<style scoped>

</style>